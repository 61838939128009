<template>
  <van-nav-bar
    :title="title"
  />

  <div class="pirce-total">
    分账累计总和：{{totalAmount || 0}} 元
  </div>

  <div class="seller">
    <van-cell
      title="开始时间"
      is-link
      :value-class="className"
      :value="timeValue"
      @click="showPopup" />
    <van-popup v-model:show="show" position="bottom">
      <van-datetime-picker
        v-model="startCreateTime"
        type="date"
        title="选择时间"
        :loading="isLoadingShow"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="show = false"
        @confirm="confirmPicker"
      />
    </van-popup>

    <van-cell
      title="结束时间"
      is-link
      :value-class="className"
      :value="timeValueend"
      @click="showPopupend" />
    <van-popup v-model:show="showend" position="bottom">
      <van-datetime-picker
        v-model="endCreateTime"
        type="date"
        title="选择时间"
        :loading="isLoadingShowend"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="showend = false"
        @confirm="confirmPickerend"
      />
    </van-popup>
    <div style="display: flex;align-items: center;">
      <van-button type="primary" block @click="getDatatime">查询</van-button>
    </div>
  </div>

  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in list" :key="item">
      <div style="background:#fff;padding:15px;margin:15px 0 0 0;">
        <p style="margin:0 0 15px 0;">
          <small v-if="item.name">分润接收方 {{ item.name }}</small>
          <small v-if="!item.name && item.user">分润接收方 {{ item.user.nickName || item.user.name }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>接收方手机号 {{ item.user ? item.user.phone : '--' }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>接收方余额 &yen;{{ item.user ? item.user.balance : '--' }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>分红总金额 &yen;{{ item.amount }}</small>
        </p>
        <van-button type="primary" size="small" style="margin-right: 8px;"  :to="{ name: 'ExportPage', query: { startCreateTime: startCreateTime,endCreateTime:endCreateTime,userId:item.user.userId,storeId:storeId,userName:item.name,storeName:item.store.name,userPhone:item.user.phone } }">导出明细</van-button>
        <van-button size="small" @click="getDatadetails(item.user.userId)">详情</van-button>
      </div>
    </template>
  </van-list>

  <!-- 分红统计详情弹窗 -->
  <van-popup
    v-model:show="showdetails"
    closeable
    close-icon="close"
    position="bottom"
    :style="{ height: '80%' }"
  >
    <van-nav-bar
      :title="titledetails"
    />
    <van-list
      v-model:loading="detailloading"
      :finished="finisheddetails"
      finished-text="没有更多了"
      :immediate-check="false"
      @load="getDatadetailses"
    >
      <template v-for="itemdetails in listdetails" :key="itemdetails">
        <div style="background:#fff;padding:15px;margin:15px 0 0 0;">
          <p style="margin:0 0 15px 0;">
            <small>分润接收方 {{ itemdetails.memberName }}</small>
          </p>
          <p style="margin:0 0 15px 0;">
            <small>状态 {{ itemdetails.statusText ?? '等待结算' }}</small>
          </p>
          <p style="margin:0 0 15px 0;">
            <small>金额 &yen;{{ itemdetails.amount }}</small>
          </p>
          <p style="margin:0 0 15px 0;">
            <small>备注 {{ itemdetails.memo ?? '' }}</small>
          </p>
          <p style="margin:0 0 15px 0;">
            <small>类型 {{ itemdetails.typeText ?? '销售分润' }}</small>
          </p>
          <p style="margin:0 0 15px 0;">
            <small>时间 {{ itemdetails.createTime }}</small>
          </p>
        </div>
      </template>
    </van-list>
  </van-popup>
</template>

<script>

import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const state = reactive({
      timeValue: '请选择时间',
      timeValueend: '请选择时间',
      show: false,
      isLoadingShow: true,
      startCreateTime: '',
      endCreateTime: '',
      showend: false,
      showdetails: false,
      isLoadingShowend: true,
      minDate: new Date(2010, 12, 31),
      maxDate: new Date(),
      className: '',
      page: 1,
      pagedetails: 1,
      finished: false,
      finisheddetails: false,
      loading: false,
      loadingdetails: false,
      detailloading: false,
      list: [],
      listdetails: [],
      title: '',
      titledetails: '',
      storeId: Cookies.get('storeId'),
      orderId: useRoute.query.orderId,
      userId: '',
      v1: 0,
      v2: 0,
      v3: 0,
      v4: 0,
      totalAmount: 0
    })
    const showPopup = () => {
      state.show = true
      state.isLoadingShow = true
      setTimeout(() => {
        state.isLoadingShow = false
      }, 500)
    }
    const showPopupend = () => {
      state.showend = true
      state.isLoadingShowend = true
      setTimeout(() => {
        state.isLoadingShowend = false
      }, 500)
    }
    // 确认选择的时间
    const confirmPicker = (val) => {
      console.log(val)
      const year = val.getFullYear()
      let month = val.getMonth() + 1
      let day = val.getDate()
      if (month >= 1 && month <= 9) { month = `0${month}` }
      if (day >= 1 && day <= 9) { day = `0${day}` }
      state.className = 'timeClass'
      state.timeValue = `${year}-${month}-${day}`
      state.show = false
      state.startCreateTime = state.timeValue + ' 00:00:00'
    }
    const confirmPickerend = (val) => {
      const year = val.getFullYear()
      let month = val.getMonth() + 1
      let day = val.getDate()
      if (month >= 1 && month <= 9) { month = `0${month}` }
      if (day >= 1 && day <= 9) { day = `0${day}` }
      state.className = 'timeClass'
      state.timeValueend = `${year}-${month}-${day}`
      state.showend = false
      state.endCreateTime = state.timeValueend + ' 23:59:59'
    }
    const onClickLeft = () => {
      state.$router.go(-1)
    }
    const getData = () => {
      post('/staffcommission.sum', {
        pageNum: state.page,
        storeId: state.storeId,
        startCreateTime: state.startCreateTime,
        endCreateTime: state.endCreateTime
      }).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
        state.totalAmount = res.data.totalAmount
      })
    }
    const getDatatime = () => {
      state.list = []
      state.page = 1
      getData()
    }
    const getDatadetails = (userId) => {
      state.userId = userId
      state.listdetails = []
      state.pagedetails = 1
      getDatadetailses('cover')
      state.showdetails = true
    }
    const getDatadetailses = (cover) => {
      state.detailloading = true
      post('/staffcommission.sum.details', {
        pageNum: state.pagedetails,
        storeId: state.storeId,
        userId: state.userId,
        startCreateTime: state.startCreateTime,
        endCreateTime: state.endCreateTime
      }).then(res => {
        state.detailloading = false
        console.log(2222, state.detailloading)
        if (res.code === 0) {
          if (cover) {
            state.listdetails = res.data.content
          } else {
            state.listdetails = [...state.listdetails, ...res.data.content]
          }
          state.loadingdetails = false
          state.finisheddetails = res.data.last
          state.pagedetails++
          state.titledetails = '总共 ' + res.data.totalElements + ' 条记录'
        } else {
          Toast(res.msg)
        }
      })
    }
    const sum = () => {
      // post('/order.sumOrder', {
      //   storeId: state.storeId,
      //   bizScope: 'DEPOSIT',
      //   status: 'COMPLETED'
      // }).then(res => {
      //   state.v1 = res.data.payAmount
      //   state.v2 = res.data.depositAmount
      //   state.v3 = res.data.depositGiftAmount
      // })
      // post('/member.sumDeposit', {
      //   storeId: state.storeId
      // }).then(res => {
      //   state.v4 = res.data.total
      // })
    }
    const init = () => {
      sum()
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      showPopup,
      showPopupend,
      confirmPicker,
      confirmPickerend,
      onClickLeft,
      getDatatime,
      getDatadetails,
      getDatadetailses
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
.pirce-total{
  width: 100%;
  line-height: 80rpx;
  color: var(--van-nav-bar-title-text-color);
  font-weight: var(--van-font-weight-bold);
  font-size: var(--van-nav-bar-title-font-size);
  background-color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
